<template>
  <v-autocomplete v-bind="$attrs" v-on="$listeners" v-model="fieldValue">
    <template v-slot:append-outer>
      <base-tooltip-button
        :fab="true"
        :small="true"
        :disabled="disabledInclude"
        v-if="visibleInclude"
        @click="onInclude()"
      >
        <!-- icon name -->
        <template v-slot:icon-name>
          {{ includeIcon }}
        </template>

        <!-- toolTip -->
        <template v-slot:toolTip>
          {{ toolTipInclude }}
        </template>
      </base-tooltip-button>
    </template>
  </v-autocomplete>
</template>

<script>
// design
import {
  iconTrash,
  iconMinus,
  iconCheckbox,
  iconCheckboxBlank
} from "@/design/icon/iconConst";

export default {
  name: "SearchSelectFormField",
  inheritAttrs: false,
  components: {
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton")
  },
  data() {
    return {
      iconTrash: iconTrash,
      iconMinus: iconMinus,
      iconCheckbox: iconCheckbox,
      iconCheckboxBlank: iconCheckboxBlank
    };
  },
  props: {
    /**
     * Form Field
     * @type {{id:number, name:string, label:string, fieldDataType:number, value: *, operators:[{operator:string, description:string}]}}
     */
    field: undefined,
    /**
     * Is removable field
     */
    removable: {
      type: Boolean,
      default: false
    },
    visibleInclude: {
      type: Boolean,
      default: true
    },
    disabledInclude: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fieldValue: {
      get() {
        return this.field?.value;
      },
      set(value) {
        if (this.field) {
          this.field.value = value;
        }
      }
    },
    fieldOperator: {
      get() {
        return this.field?.operator ?? "";
      },
      set(value) {
        if (this.field) {
          this.field.operator = value;
        }
      }
    },
    fieldInclude: {
      get() {
        return this.field?.include ?? false;
      },
      set(value) {
        if (this.field) {
          this.field.include = value;
        }
      }
    },
    fieldLabel() {
      return this.field?.label ?? "Field ?";
    },
    toolTipRemove() {
      return `Remove ${this.fieldLabel}`;
    },
    toolTipInclude() {
      return `Include ${this.fieldLabel} in search results`;
    },
    includeIcon() {
      return this.fieldInclude ? this.iconCheckbox : this.iconCheckboxBlank;
    }
  },
  methods: {
    onInclude() {
      this.fieldInclude = !this.fieldInclude;
    }
  }
};
</script>
